import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "tailwindcss/tailwind.css"
import "./App.css";

import TradingViewWidget, { Themes } from 'react-tradingview-widget';
import MessagesList from "./components/messages-list.component";

class App extends Component {
  render() {
    return (
      <div>
          <div className="main-container row">
            <div className="col-md-8 hidden-sm-down">
              <TradingViewWidget
                  symbol="BYBIT:BTCUSD"
                  theme={Themes.DARK}
                  locale="en"
                  interval= "5"
                  style="0"
                  autosize
                  hide_side_toolbar={false}
                  container_id="tradingview_f962f"
              />
            </div>
            <div className="col-md-4 message-box">
              <MessagesList
              />
            </div>
          </div>
        
      </div>
    );
  }
}

export default App;
