import React, { Component } from "react";

import { Avatar, Card, CardBody, Button } from '@windmill/react-ui';
import ModalImage from "react-modal-image";
import Linkify from 'react-linkify';
import { FlagIcon, TrashIcon } from '@heroicons/react/outline'

import MessageDataService from "../services/message.service";

export default class MessagesList extends Component {
  constructor(props) {
    super(props);
    this.onDataChange = this.onDataChange.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);
    this.archiveMessage = this.archiveMessage.bind(this);

    this.state = {
      messages: []
    };
  }

  componentDidMount() {
    MessageDataService.getAll().on("value", this.onDataChange);
  }

  componentWillUnmount() {
    MessageDataService.getAll().off("value", this.onDataChange);
  }

  onDataChange(items) {
    let messages = [];

    items.forEach((item) => {
      let key = item.key;
      let data = item.val();
      messages.push({
        key: data.key,
        id: data.id,
        content: data.content,
        message_reference: data.message_reference,
        author: data.author,
        attachments: data.attachments,
        timestamp: data.timestamp,
        removed: data.removed,
        archived: data.archived,
        link: data.link
      });
    });

    this.setState({
      messages: messages.reverse(),
    });
  }

  deleteMessage(key) {
    const data = {
      removed: true
    };
    MessageDataService.update(key, data)
      .then(() => {
        this.setState({
          message: "The message was removed successfully!",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  archiveMessage(key, flag) {
    const data = {
      archived: !flag
    };
    MessageDataService.update(key, data)
      .then(() => {
        this.setState({
          message: "The message was archived successfully!",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  render() {
    const { messages } = this.state;

    return (
      <div className="list">
        <div>
          <a href="https://discord.com/channels/524986738050072576/558383541528821777" target="_blank">
            <h4 className="font-color cc-pool-title">CC Pool</h4>
          </a>

          <ul className="list-group">
            {messages &&
              messages.map((message, index) => (
                <Card className="message-cards">
                  <CardBody>
                    <p className="mb-4">                      
                        <div className="grid grid-cols-10 gap-1 message-from">
                          <div className="flex justify-end col-span-1 avatar-holder">
                            <a href={message.link} target="_blank">
                              <Avatar size="large" src={message.author.dp}/>
                            </a>
                          </div>
                          <div className="text-left col-span-7 justify-center">
                            <a href={message.link} target="_blank">
                              <p className="text-base font-medium0">
                                {message.author.username}
                              </p>
                              <p className="mt-1 text-sm text-gray-500">
                                ({(new Date(message.timestamp)).toLocaleString()})
                              </p>
                            </a>
                          </div>
                          <div className="text-right col-span-2">
                            <Button
                              icon={FlagIcon}
                              size="small"
                              layout="outline"
                              aria-label="Flag"
                              className={message.archived ? 'bg-yellow-200' : ''}
                              onClick={() => this.archiveMessage(message.id, message.archived)}
                            />
                            <Button
                              icon={TrashIcon}
                              size="small"
                              layout="outline"
                              aria-label="Delete"
                              className={message.archived ? 'hidden' : ''}
                              onClick={() => this.deleteMessage(message.id)}
                            />
                          </div>
                        </div>
                    </p>
                    <p>
                      <Card className={message.message_reference ? 'ref-cards' : 'ref-cards hidden'}>
                        <CardBody>
                          <div>
                            <Linkify>{message.message_reference?.content}</Linkify>
                          </div>
                        </CardBody>
                      </Card>
                      <div className="message-content">
                        <Linkify>{message.content}</Linkify>
                      </div>
                      {message.attachments &&
                       message.attachments.map((attachment, index) => {
                          
                          if(attachment.type === "image"){
                            return <ModalImage
                              small={attachment.url}
                              large={attachment.url}
                            />
                          }
                          if((attachment.type === "video" || attachment.type === "gifv") && attachment.thumbnail){
                            return <ModalImage
                              small={attachment.thumbnail.url}
                              large={attachment.thumbnail.url}
                            />
                          }
                        
                      })}
                    </p>
                  </CardBody>
                </Card>
                
              ))}
          </ul>
        </div>
      </div>
    );
  }
}
