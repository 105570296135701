import * as firebase from "firebase";
import "firebase/database";

let config = {
  apiKey: "AIzaSyDBcO3nagc4RrEVQYtTwqbeDI0_HU9UGWY",
  authDomain: "discord-scalper.firebaseapp.com",
  databaseURL: "https://discord-scalper-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "discord-scalper",
  storageBucket: "discord-scalper.appspot.com",
  messagingSenderId: "360835007007",
  appId: "1:360835007007:web:8dd383b816eaf967db8b2a",
  measurementId: "G-8PN3ZQB5C4"
};

firebase.initializeApp(config);

const database = firebase.database();
const messaging = firebase.messaging();

function InitializeFireBaseMessaging() {
  messaging
      .requestPermission()
      .then(function () {
          return messaging.getToken();
      })
      .then(function (token) {
          database.ref("/clients").child(
            token.substr(0, token.indexOf(':'))
          ).update({
            "token": token,
            "active": true
          });
      })
      .catch(function (reason) {
          console.log(reason);
      });
}

messaging.onMessage(function (payload) {
  console.log(payload);
  const notificationOption={
      body:payload.notification.body,
      icon: '../public/cc_logo.jpg'
  };
  console.log(Notification.permission)
  if(Notification.permission==="granted"){
    var notification=new Notification(payload.notification.title,notificationOption);

    notification.onclick=function (ev) {
        ev.preventDefault();
        window.open(payload.notification.click_action,'_blank');
        notification.close();
    }
  }
});

messaging.onTokenRefresh(function () {
  messaging.getToken()
      .then(function (token) {
          database.ref("/clients").child(
            token.substr(0, token.indexOf(':'))
          ).update({
            "token": token,
            "active": true
          });
      })
      .catch(function (reason) {
          console.log(reason);
      })
})

InitializeFireBaseMessaging();

export const FirebaseRTB = firebase.database();