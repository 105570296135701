import { FirebaseRTB } from "../firebase";

const db = FirebaseRTB.ref("/messages");

class MessagingDataService {
  getAll() {
    return db.orderByChild('removed').equalTo(false).limitToLast(50);
  }

  update(key, value) {
    return db.child(key).update(value);
  }

  delete(key) {
    return db.child(key).remove();
  }

  deleteAll() {
    return db.remove();
  }
}

export default new MessagingDataService();
